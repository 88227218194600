@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "baloo";
  src: url(./assets/fonts/baloo.ttf) format("opentype");
}

h1,
header,
section#home {
  font-family: baloo;
}

a:link {
  color: #ffffff;
}
/* A link that has been visited */
a:visited {
  color: #ffffff;
}
/* A link that is hovered on */
a:hover {
  color: #dbe4ed;
}

header {
  background-color: #a3bad2;
}

header nav.meta {
  background-color: #112236;
}

header nav .navbar-nav .dropdown .dropdown-menu {
  min-width: fit-content;
  width: fit-content;
  padding-top: 0;
  padding-bottom: 0;
}

header nav .navbar-nav .dropdown .dropdown-menu .dropdown-item:active,
header nav .navbar-nav .dropdown .dropdown-menu .dropdown-item.active {
  background-color: #a3bad2;
  color: rgba(0, 0, 0, 0.55);
}

section#home {
  background-image: url(./assets/images/bg-home.jpg);
  background-repeat: repeat-x;
  background-color: #dbe4ed;
  padding: 90px 50px;
}

section#home div.card {
  background-color: #112236;
}

section#home div.info-card {
  min-height: 350px;
}

section#home form textarea {
  height: 92px;
}

section#home form .grecaptcha-badge {
  visibility: hidden;
}

section#aboutUs,
section#gallery,
section#tourism,
section#videos {
  background-color: #fff;
  font-family: verdana;
  padding: 90px 50px;
}

section#services,
section#ourTeam,
section#contact {
  background-color: #dbe4ed;
  font-family: verdana;
  padding: 90px 50px;
}

section#gallery div.image-gallery button.image-gallery-icon {
  color: #dbe4ed;
}

section#gallery div.image-gallery button.image-gallery-icon:hover {
  color: #a3bad2;
}

section#gallery div.image-gallery button.image-gallery-thumbnail {
  border-color: white;
}

section#gallery div.image-gallery button.image-gallery-thumbnail.active,
section#gallery div.image-gallery button.image-gallery-thumbnail:focus,
section#gallery div.image-gallery button.image-gallery-thumbnail:hover {
  border-color: #a3bad2;
}

section#contact div.leaflet-container {
  width: 100%;
  height: 350px;
}

section#contact div.leaflet-container .leaflet-control a,
section#contact div.leaflet-container .leaflet-control a:hover {
  color: black;
}

section#contact div.leaflet-container .leaflet-popup-content a,
section#contact div.leaflet-container .leaflet-popup-content a:hover {
  color: #333;
}

section#contact div.info-card {
  background-color: #112236;
  color: #dbe4ed;
  font-family: baloo;
  min-height: 350px;
}

#contact h2 a,
#home a {
  text-decoration: none;
}

#contact h2 a:hover,
#home a:hover {
  text-decoration: underline;
  color: #dbe4ed;
}

footer {
  background-color: #112236;
}

/* .fullscreen .image-gallery-image {
  height: 100%;
} */

@media (min-width: 992px) {
  .image-gallery {
    padding: 0 150px 0 150px;
  }
}
